import React from 'react';
import { isAuthenticated, login, getIdToken } from "../utils/auth"
import Layout from '../components/Layout';
import uuid from 'react-uuid'
import { format } from 'date-fns'

const OrdersPage = () => {

    const [orderTitle, setOrderTitle] = React.useState("");
    const [orderDescription, setOrderDescription] = React.useState("");

    const [editOrderId, setEditOrderId] = React.useState("");
    const [editOrderTitle, setEditOrderTitle] = React.useState("");
    const [editOrderDescription, setEditOrderDescription] = React.useState("");
    const [editOrderDone, setEditOrderDone] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [tableData, setTableData] = React.useState("");
    let tableDataTemp = [];

    const handleOrderTitleChange = (event) => {
        const value = event.target.value

        setOrderTitle(value)
    }

    const handleOrderDescriptionChange = (event) => {
        const value = event.target.value

        setOrderDescription(value)
    }

    const handleEditOrderTitleChange = (event) => {
        const value = event.target.value

        setEditOrderTitle(value)
    }

    const handleEditOrderDescriptionChange = (event) => {
        const value = event.target.value

        setEditOrderDescription(value)
    }

    const handleEditOrderDoneChange = (event) => {
        const value = event.target.checked

        setEditOrderDone(value)
    }

    const handleShowEditFormChange = (id, title, description, done) => {
        setEditOrderId(id);
        setEditOrderTitle(title)
        setEditOrderDescription(description);
        setEditOrderDone(done)
        setShowEditForm(true);
    }

    const handleAddOrder = async () => {

        const token = getIdToken();

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({ title: orderTitle, description: orderDescription }),
        };

        try {
            const response = await fetch(process.env.GATSBY_ORDERS_URL, requestOptions);

            if (response.status === 200) {
                setOrderTitle('');
                setOrderDescription('');
                fetchOrders();
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleDeleteOrder = async (id) => {
        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_ORDERS_URL);
        url.searchParams.append("id", id);

        try {
            await fetch(url, requestOptions)
            await fetchOrders();
        }
        catch (err) {
            console.log(err)
        }
    }

    const addDataToTableSource = (data) => {
        let tableRow = {
            id: data._id,
            title: data.title,
            description: data.description,
            created: format(new Date(data.created), 'yyyy-MM-dd HH:mm:ss'),
            updated: format(new Date(data.updated), 'yyyy-MM-dd HH:mm:ss'),
            done: data.done
        }

        tableDataTemp.push(tableRow);
    }

    const fetchOrders = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let response = await fetch(process.env.GATSBY_ORDERS_URL, requestOptions);
        let responseJson = await response.json();

        responseJson.forEach(data => {
            addDataToTableSource(data);
        })

        setTableData(tableDataTemp);
    }

    const editOrderItem = async () => {

        const token = getIdToken();

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Authorization': 'Bearer ' + token }
        };

        let url = new URL(process.env.GATSBY_ORDERS_URL);
        url.searchParams.append("id", editOrderId);
        url.searchParams.append("title", editOrderTitle);
        url.searchParams.append("done", editOrderDone);
        url.searchParams.append("description", editOrderDescription);

        try {
            const response = fetch(url, requestOptions)

            if (response.status === 200) {
                setEditOrderId("");
                setEditOrderTitle("")
                setEditOrderDescription("");
                setEditOrderDone(false)
                setShowEditForm(false)
                await fetchOrders();
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const EditOrdersItem = () => {
        return (
            <div className="row" key={uuid()} id="edit">
                <div className="col-12">
                    <h4>Edit Order</h4>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="order-title">Order Title</label></li>
                        <li><input type="text" name="order-title" id="order-title" value={editOrderTitle} onChange={(event) => handleEditOrderTitleChange(event)} /></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt">
                        <li><label htmlFor="order-description">Description</label></li>
                        <li><textarea name="order-description" id="order-description" rows="3" value={editOrderDescription} onChange={(event) => handleEditOrderDescriptionChange(event)}></textarea></li>
                    </ul>
                </div>
                <div className="col-4 col-12-small">
                    <ul className="alt actions fit small">
                        <li><input type="checkbox" id="order-done" name="order-done" checked={editOrderDone} onChange={(event) => handleEditOrderDoneChange(event)} />
                            <label htmlFor="order-done">Done</label></li>
                        <li><a href="#read" className="button primary fit small" onClick={() => editOrderItem()}>Edit Item</a></li>
                    </ul>
                </div>
            </div>
        )
    }

    const OrdersListTable = () => {
        return ((tableData !== undefined) && (tableData.length !== undefined) && tableData.length > 0 && (
            tableData.map((row) => (
                <div className="row" key={uuid()}>
                    <div className="col-12">
                        <h4>{row.title}</h4>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>{row.description}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt">
                            <li>Done:  {row.done === true ? "True" : "False"}</li>
                            <li>Created:  {row.created}</li>
                            <li>Updated:  {row.updated}</li>
                        </ul>
                    </div>
                    <div className="col-4 col-12-small">
                        <ul className="alt actions fit small">
                            <li><a href="#edit" className="button primary fit small" onClick={() => handleShowEditFormChange(row.id, row.title, row.description, row.done)}>Edit</a></li>
                            <li><a href="#read" className="button fit small" onClick={() => handleDeleteOrder(row.id)}>Delete</a></li>
                        </ul>
                    </div>
                </div>
            ))
        ))
    }

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout fullMenu>
            <section id="banner">
                <header>
                    <div className="inner">
                        <h2>Orders</h2>
                    </div>
                </header>
                <div className="wrapper" id="read">
                    <div className="inner">
                        <h3>Orders List</h3><br /><br />
                        <div className="col-12">
                            <ul className="actions">
                                <li><a href="#read" className="button primary" onClick={() => fetchOrders()}>Get Orders</a></li>
                            </ul>
                        </div>
                        {showEditForm && <EditOrdersItem />}
                        <OrdersListTable />
                    </div>
                </div>
                <div className="wrapper" id="add">
                    <div className="inner">
                        <h3>Add Order</h3><br /><br />
                        <div className="row gtr-uniform">
                            <div className="col-6 col-12-xsmall">
                                <label htmlFor="order-title">Order Title</label>
                                <input type="text" name="order-title" id="order-title" value={orderTitle} onChange={(event) => handleOrderTitleChange(event)} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="order-description">Description</label>
                                <textarea name="order-description" id="order-description" rows="3" value={orderDescription} onChange={(event) => handleOrderDescriptionChange(event)}></textarea>
                            </div>
                            <div className="col-12">
                                <ul className="actions">
                                    <li><a href="#add" className="button primary" onClick={() => handleAddOrder()}>Add Order</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
};

export default OrdersPage;